import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import {
    BrowserRouter as Router, Route, Switch, Redirect,
} from 'react-router-dom';
import { APIURL } from '../config';

export default class TasksService {
    static async getAll(date) {
        try {
            const token = localStorage.getItem('token');
            const resp = await fetch(`${APIURL}/tasks`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({ date }),
            });
            if (resp.status == 401) {
                return <Redirect to="/logout" />;
            }
            return await resp.json();
        } catch (e) {
            console.log(`error${e}`);
        }
    }

    static async create(name, status, silo, driver, client, deliveryDate, deliveryTime, note) {
        const token = localStorage.getItem('token');
        const resp = await fetch(`${APIURL}/tasks/create-task`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                name, status, silo, driver, client, deliveryDate, deliveryTime, note,
            }),
        });
        return await resp.json();
    }

    static async update(id, name, status, silo, driver, client, deliveryDate, deliveryTime, note) {
        const token = localStorage.getItem('token');
        const resp = await fetch(`${APIURL}/tasks/${id}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                id, name, status, silo, driver, client, deliveryDate, deliveryTime, note,
            }),
        });
        return await resp.json();
    }

    static async deleteItem(id) {
        const token = localStorage.getItem('token');
        const resp = await fetch(`${APIURL}/tasks/${id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        });
        return await resp.json();
    }
}
