import axios from 'axios';
import { APIURL } from "../config";


export default class LoginService {

    static async login(loginInfo) {
        axios.defaults.headers.post['X-Requested-With'] = 'XMLHttpRequest';
        const response = await axios.post(APIURL + '/login', {
            "email": loginInfo.email,
            "password": loginInfo.password,
            'admin': true
        });
        if (response.data.user.role == "admin") {
            localStorage.setItem('token', response.data.token)
        }
        return response;
    }
}