import React, {
    Fragment, useContext, useEffect, useState,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Card, CardBody, CustomInput, Input, Form, Col,
} from 'reactstrap';
import CountUp from 'react-countup';
import TasksService from '../../services/tasks';
import {
    setTasks, setLoading, setError, setCreatedJobs, setInprogressJobs, setCompletedJobs,
} from '../../actions/tasks';
import CardSummary from './CardSummary';
import FalconCardHeader from '../common/FalconCardHeader';
import Loader from '../common/Loader';
import { getJobStatuses } from '../../helpers/utils';
import PurchasesTable from './PurchasesTable';

function Dashboard() {
    const dispatch = useDispatch();
    const tasksData = useSelector((state) => state.tasksReducer);
    const [isSelected, setIsSelected] = useState(false);
    const [filteredJobs, setFilteredJobs] = useState([]);
    const [category, setCategory] = useState('');
    const [searchText, setSearchText] = useState('');
    const [status, setStatus] = useState('');
    const [filteredData, setFilteredData] = useState([]);

    useEffect(() => {
        dispatch(setLoading(true));
        TasksService.getAll(new Date())
            .then((res) => {
                dispatch(setTasks(res));
                setFilteredData(res);
                dispatch(setLoading(false));
            })
            .catch((err) => {
                dispatch(setError(err));
                dispatch(setLoading(false));
            });
    }, []);

    const statuses = getJobStatuses();

    useEffect(() => {
        const initData = tasksData.tasks;
        let prepared = tasksData.tasks;
        /**
         * Search by text
         */
        if (tasksData && searchText !== '') {
            prepared = initData.filter(
                (data) => data.name.toLowerCase().includes(searchText.toLowerCase()),
            );
        }

        if (tasksData && status !== '') {
            prepared = prepared.filter(
                (data) => data.status === status,
            );
        }

        setFilteredData(prepared);
    }, [searchText, status, tasksData.tasks]);

    if (tasksData.tasks == null || tasksData.tasks == undefined) {
        return (
          <Loader />
        );
    }

    return (
      <>
          <div className="card-deck">
              <CardSummary title="Uzdevumi izpildei" color="warning">
                  {tasksData.loading === true
                        ? 0 : <CountUp end={tasksData.tasks && tasksData.createdJobs ? tasksData.createdJobs.length : 0} duration={3} decimal="." />}
                </CardSummary>
              <CardSummary title="Uzdevumi apskatīti" color="info">
                  {tasksData.loading === true
                        ? 0 : <CountUp end={tasksData.tasks && tasksData.inprogressJobs ? tasksData.inprogressJobs.length : 0} duration={3} decimal="." /> }
                </CardSummary>
              <CardSummary title="Pabeigtie uzdevumi" color="success">
                  {tasksData.loading === true
                        ? 0 : <CountUp end={tasksData.tasks && tasksData.completedJobs ? tasksData.completedJobs.length : 0} duration={1} decimal="." /> }
                </CardSummary>
            </div>

          <Card className="mb-3">
              <FalconCardHeader title="Uzdevumi izpildei" light={false}>
                  <Form inline>
                      <Input bsSize="sm" placeholder="Meklēt..." onChange={(el) => setSearchText(el.target.value)} />

                      <CustomInput
                          type="select"
                          id="exampleCustomSelect"
                          onChange={(el) => setStatus(el.target.value)}
                          name="customSelect"
                          bsSize="sm"
                          className="d-md-block d-none ml-2"
                        >
                          {statuses.map((option, index) => (
                                <option value={option.name} key={index}>
                                    {option.label}
                            </option>
                            ))}
                        </CustomInput>
                    </Form>
                </FalconCardHeader>
              <CardBody className="p-0">
                  {tasksData.loading ? <Loader />
                        : <PurchasesTable setIsSelected={setIsSelected} jobs={filteredData} />}
                </CardBody>
            </Card>
        </>
    );
}

export default Dashboard;
