import { createContext } from 'react';

const AppContext = createContext({
  isFluid: false,
  isRTL: false,
  isDark: false,
  showBurgerMenu: false, // controls showing vertical nav on mobile
  currency: '$',
  isLoggedIn: false
});

export const UserDataContext = createContext({
  userData: [],
});

export const EmailContext = createContext({ emails: [] });

export const LoginContext = createContext({
  setEmail: () => {},
  setPassword: () => {},
  email: "",
  password: "",
});


export const LayoutContext = createContext({
  countriesArr: [],
});


export const ProductContext = createContext({ products: [] });

export const FeedContext = createContext({ feeds: [] });

export default AppContext;
