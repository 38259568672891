import { version } from './config';
import {
    faAddressCard,
    faBook,
    faCalendar,
    faCheck,
    faCity,
    faCoffee,
    faCreditCard,
    faMoneyBillAlt,
    faMoneyCheckAlt,
    faTable,
    faTag,
    faUser,
    faWrench,
    wrench,
    faUsers,
    faHome,
    faTruck,
    faTrailer,
    faTruckLoading,
    faChartBar
} from '@fortawesome/free-solid-svg-icons';
import { faList } from "@fortawesome/free-solid-svg-icons/faList";

export const homeRoutes = {
    name: 'Sākums',
    to: '/',
    exact: true,
    icon: faHome,
};

export const tasks = {
    name: 'Darāmie darbi',
    to: '/tasks',
    exact: true,
    icon: faList,
};

export const drivers = {
    name: 'Šoferi',
    to: '/drivers',
    exact: true,
    icon: faUser,
};


export const clients = {
    name: 'Klienti',
    to: '/clients',
    exact: true,
    icon: faUsers,
};

export const cars = {
    name: 'Auto',
    to: '/cars',
    exact: true,
    icon: faTruck,
};

export const trailers = {
    name: 'Piekabes',
    to: '/trailers',
    exact: true,
    icon: faTrailer,
};

export const silos = {
    name: 'Silos',
    to: '/silos',
    exact: true,
    icon: faTruckLoading,
};

export const reports = {
    name: 'Atskaites',
    to: '/reports',
    exact: true,
    icon: faChartBar,
};

// export const settings = {
//     name: 'Uzstādījumi',
//     to: '/pages/settings',
//     exact: true,
//     icon: faWrench,
// };


export const login = {
    name: 'Login',
    to: '/login',
    // exact: true,
    // icon: 'home',
};

export const logout = {
    name: 'Logout',
    to: '/logout',
    // exact: true,
    // icon: 'home',
};

export default [
    homeRoutes,
    tasks,
    drivers,
    clients,
    cars,
    trailers,
    silos,
    reports,
    // settings
];