import react from 'react';
import { toast } from "react-toastify";

export const errorContainer = (err) => {

    if (err && typeof err.msg !== 'undefined' && err.msg) {
        console.log('???', err)
        // param name
        if (err.param == "name") {
            err.param = "Nosaukums"
        } else if (err.param == "password") {
            err.param = "Parole"
        } else if (err.param == "phone") {
            err.param = "Telefona nr"
        } else if (err.param == "email") {
            err.param = "E-pasts"
        } else if (err.param == "address") {
            err.param = "Adrese"
        } else if (err.param == "distance") {
            err.param = "Distance"
        } else if (err.param == "deliveryDate") {
            err.param = "Piegādes datums"
        } else if (err.param == "deliveryTime") {
            err.param = "Piegādes laiks"
        } else if (err.param == "silo") {
            err.param = "Silo"
        } else if (err.param == "driver") {
            err.param = "Šoferis"
        } else if (err.param == "client") {
            err.param = "Klients"
        } else if (err.param == "trailer") {
            err.param = "Treileris"
        } else if (err.param == "car") {
            err.param = "Auto"
        }

        // message name
        if (err.msg == "IS_EMPTY") {
            err.msg = "ir tukšs"
        }
        if (err.msg == "PASSWORD_TOO_SHORT_MIN_5") {
            err.msg = "Parolei vismaz 5 simboli"
        }

        if (err.msg == "CAR_ALREADY_EXISTS") {
            err.msg = "Tāds auto jau pastāv"
        }

        if (err.param) {
            toast.error(err.param + " " + err.msg)
        } else {
            toast.error(err.msg)
        }
    }

}