import { combineReducers } from 'redux';

const INITIAL_STATE = {
    loading: true,
    error: null,
    silos: []
};

const silosReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'SET_SILOS':
            return { ...state, silos: action.silos };
        case 'SET_LOADING':
            return { ...state, loading: action.flag };
        case 'SET_ERROR':
            return { ...state, error: action.error };
        case 'LOG_OUT':
            return { INITIAL_STATE };
        default:
            return state;
    }
};

export default silosReducer;