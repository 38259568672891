import React, {
    createRef, useState, useEffect,
} from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import BootstrapTable from 'react-bootstrap-table-next';
import DatePagination from 'react-date-pagination';
import TaskView from '../tasks/TaskView';
import {
    getDate, getTime, unifiedTableClass, jobStatusFormatter,
} from '../../helpers/utils';
import {
    setCreatedJobs, setInprogressJobs, setCompletedJobs,
} from '../../actions/tasks';

function customerFormatter(customerName, data) {
    return <TaskView data={data} />;
}

function order(cell, row, rowIndex) {
    return rowIndex + 1;
}

const dateFormatter = (date) => getDate(date);

const timeFormatter = (date) => getTime(date);

const columns = [{
    dataField: 'id',
    text: 'Nr',
    classes: unifiedTableClass,
    formatter: order,
},
{
    dataField: 'name',
    text: 'Nosaukums',
    classes: unifiedTableClass,
    formatter: customerFormatter,
    sort: true,
},
{
    dataField: 'silo.name',
    text: 'Silos',
    classes: unifiedTableClass,
    sort: true,
},
{
    dataField: 'driver.name',
    text: 'Šoferis',
    classes: unifiedTableClass,
    sort: true,
},
{
    dataField: 'driver.car.name',
    text: 'Auto',
    classes: unifiedTableClass,
    sort: true,
},
{
    dataField: 'driver.car.trailer.name',
    text: 'Piekabe',
    classes: unifiedTableClass,
    sort: true,
},
{
    dataField: 'client.name',
    text: 'Klients',
    classes: unifiedTableClass,
    sort: true,
},
{
    dataField: 'status',
    text: 'Status',
    classes: unifiedTableClass,
    sort: true,
    formatter: jobStatusFormatter,
},
{
    dataField: 'deliveryDate',
    text: 'Piegādes datums',
    classes: unifiedTableClass,
    sort: true,
    formatter: dateFormatter,
},
{
    dataField: 'deliveryTime',
    text: 'Piegādes laiks',
    classes: unifiedTableClass,
    sort: true,
    formatter: timeFormatter,
},
];

const SelectRowInput = ({ indeterminate, rowIndex, ...rest }) => (
    <div className="custom-control custom-checkbox">
    <input
          className="custom-control-input"
          {...rest}
          onChange={() => {
            }}
          ref={(input) => {
                if (input) input.indeterminate = indeterminate;
            }}
        />
    <label className="custom-control-label" />
  </div>
);

const selectRow = (onSelect) => ({
    mode: 'checkbox',
    clickToSelect: false,
    selectionHeaderRenderer: ({ mode, ...rest }) => <SelectRowInput type="checkbox" {...rest} />,
    selectionRenderer: ({ mode, ...rest }) => <SelectRowInput type={mode} {...rest} />,
    onSelect,
    onSelectAll: onSelect,
});

const PurchasesTable = ({ setIsSelected, jobs }) => {
    const dispatch = useDispatch();
    const [activePage, setActivePage] = useState(1);
    const [currentJobs, setCurrentJobs] = useState(jobs);

    const groups = jobs.reduce((groups, task) => {
        const date = task.createdAt.split('T')[0];
        if (!groups[date]) {
            groups[date] = [];
        }
        groups[date].push(task);
        return groups;
    }, {});

    const groupArrays = Object.keys(groups).map((date) => ({
        date,
        tasks: groups[date],
    }));

    useEffect(() => {
        const currentJobs = groupArrays.filter((tasks, index) => index + 1 == activePage);
        if (currentJobs[0] !== undefined) {
            setCurrentJobs(currentJobs[0].tasks);
            dispatch(setCreatedJobs(currentJobs[0].tasks.filter((task) => task.status == 'created')));
            dispatch(setInprogressJobs(currentJobs[0].tasks.filter((task) => task.status == 'inprogress')));
            dispatch(setCompletedJobs(currentJobs[0].tasks.filter((task) => task.status == 'completed')));
        }
    }, [activePage]);

    const options = {
        custom: true,
        sizePerPage: currentJobs.length,
        totalSize: jobs.length,
    };

    const table = createRef();

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
    };

    return (
        <>
        <div className="table-responsive">
              <BootstrapTable
                  ref={table}
                  bootstrap4
                  keyField="_id"
                  data={jobs}
                  columns={columns}
                    // selectRow={selectRow(onSelect)}
                  bordered={false}
                  classes="table-dashboard table-sm fs--1 border-bottom mb-0 table-dashboard-th-nowrap"
                  rowClasses="btn-reveal-trigger"
                  headerClasses="bg-200 text-900"
                />
            </div>
      </>
    );
};

PurchasesTable.propTypes = { value: PropTypes.any };

PurchasesTable.defaultProps = { value: 'PurchasesTable' };

export default PurchasesTable;
