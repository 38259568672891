import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Main from './Main';
import './helpers/initFA';
import { applyMiddleware, createStore } from "redux";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import reducers from './reducers';
import { store, persistor } from './configureStore';
import ReduxThunk from 'redux-thunk';

ReactDOM.render(
    <Provider store={store}>
    	<PersistGate loading={null} persistor={persistor}>
        <Main>
            <App />
        </Main>
        </PersistGate>
    </Provider>,
    document.getElementById('main')
);