import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { setUser, setLoading, setError } from "../../actions/user";
import { toast } from 'react-toastify';
import { Button, Form, Row, Col, FormGroup, Input, CustomInput, Label } from 'reactstrap';
import withRedirect from '../../hoc/withRedirect';
import LoginService from "../../services/login";
import { LoginContext } from "../../context/Context";

const LoginForm = ({ setRedirect, redirect, hasLabel, layout, props, params, redirectHome }) => {
    // State
    const [isDisabled, setIsDisabled] = useState(true);
    const { email, setEmail, password, setPassword } = useContext(LoginContext);
    const [redirectStart, setRedirectStart] = useState(false);
    const [loggedIn, setLoggedIn] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        setIsDisabled(!email || !password);
    }, [email, password, setRedirect, setRedirectStart]);

    // Handler
    const handleSubmit = async (e) => {
        e.preventDefault();


        const authHandler = async (setRedirect) => {
            try {
                const userData = await LoginService.login({ email, password });
                console.log('re ', userData);
                if (userData.data.token) {
                    if (userData.data.user.role == "user") {
                        toast.success("Jums nav tiesību ielogoties");
                    } else {
                        dispatch(setUser(userData.data.token, userData.data.user))
                        toast.success(`Ienāca kā ${email}`);
                        redirectHome();
                    }
                }

            } catch (err) {
                console.log('ble err', err.response)
                toast.error(`Authentication failed`);
            }
        };

        await authHandler(setRedirect);
    };

    return (
        <Form onSubmit={handleSubmit}>
      <FormGroup>
        {hasLabel && <Label>Email address</Label>}
        <Input
          placeholder={!hasLabel ? 'Email address' : ''}
          value={email}
          onChange={({ target }) => setEmail(target.value)}
          type="email"
        />
      </FormGroup>
      <FormGroup>
        {hasLabel && <Label>Password</Label>}
        <Input
          placeholder={!hasLabel ? 'Password' : ''}
          value={password}
          onChange={({ target }) => setPassword(target.value)}
          type="password"
        />
      </FormGroup>
      <FormGroup>
        <Button color="primary" block className="mt-3" disabled={isDisabled}>
          Ienākt
        </Button>
      </FormGroup>
    </Form>
    );
};

LoginForm.propTypes = {
    setRedirect: PropTypes.func.isRequired,
    layout: PropTypes.string,
    hasLabel: PropTypes.bool
};

LoginForm.defaultProps = {
    layout: 'basic',
    hasLabel: false
};

export default withRedirect(LoginForm);