export const setLoading = (flag) => {
    return {
        type: 'SET_LOADING',
        flag
    }
}

export const setError = (error) => {
    return {
        type: 'SET_ERROR',
        error
    }
}

export const setUser = (token, user) => {
    return {
        type: 'SET_USER',
        token,
        user
    }
}