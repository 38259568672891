import { combineReducers } from 'redux';

const INITIAL_STATE = {
    loading: false,
    error: null,
    token: null,
    user: null
};

const userReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'SET_USER':
            return { ...state, token: action.token, user: action.user };
        case 'SET_LOADING':
            return { ...state, loading: action.flag };
        case 'SET_ERROR':
            return { ...state, error: action.error };
        case 'LOG_OUT':
            return { INITIAL_STATE };
        default:
            return state;
    }
};

export default userReducer;