import { combineReducers } from 'redux';

const INITIAL_STATE = {
    loading: false,
    error: null,
    trailers: []
};

const trailersReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'SET_TRAILERS':
            return { ...state, trailers: action.trailers };
        case 'SET_LOADING':
            return { ...state, loading: action.flag };
        case 'SET_ERROR':
            return { ...state, error: action.error };
        case 'LOG_OUT':
            return { INITIAL_STATE };
        default:
            return state;
    }
};

export default trailersReducer;