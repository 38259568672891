import moment from 'moment';
import { faInfinity, faList } from "@fortawesome/free-solid-svg-icons";
import Badge from "reactstrap/es/Badge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export const isIterableArray = array => Array.isArray(array) && !!array.length;

//===============================
// Store
//===============================
export const getItemFromStore = (key, defaultValue, store = localStorage) =>
    JSON.parse(store.getItem(key)) || defaultValue;
export const setItemToStore = (key, payload, store = localStorage) => store.setItem(key, JSON.stringify(payload));
export const getStoreSpace = (store = localStorage) =>
    parseFloat((escape(encodeURIComponent(JSON.stringify(store))).length / (1024 * 1024)).toFixed(2));

//===============================
// Moment
//===============================
export const getDuration = (startDate, endDate) => {
    if (!moment.isMoment(startDate)) throw new Error(`Start date must be a moment object, received ${typeof startDate}`);
    if (endDate && !moment.isMoment(endDate))
        throw new Error(`End date must be a moment object, received ${typeof startDate}`);

    return `${startDate.format('ll')} - ${endDate ? endDate.format('ll') : 'Present'} • ${startDate.from(
    endDate || moment(),
    true
  )}`;
};

export const numberFormatter = (number, fixed = 2) => {
    // Nine Zeroes for Billions
    return Math.abs(Number(number)) >= 1.0e9 ?
        (Math.abs(Number(number)) / 1.0e9).toFixed(fixed) + 'B' : // Six Zeroes for Millions
        Math.abs(Number(number)) >= 1.0e6 ?
        (Math.abs(Number(number)) / 1.0e6).toFixed(fixed) + 'M' : // Three Zeroes for Thousands
        Math.abs(Number(number)) >= 1.0e3 ?
        (Math.abs(Number(number)) / 1.0e3).toFixed(fixed) + 'K' :
        Math.abs(Number(number)).toFixed(fixed);
};

//===============================
// Colors
//===============================
export const hexToRgb = hexValue => {
    let hex;
    hexValue.indexOf('#') === 0 ? (hex = hexValue.substring(1)) : (hex = hexValue);
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
        hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b)
    );
    return result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)] : null;
};

export const rgbColor = (color = colors[0]) => `rgb(${hexToRgb(color)})`;
export const rgbaColor = (color = colors[0], alpha = 0.5) => `rgba(${hexToRgb(color)},${alpha})`;

export const colors = [
    '#2c7be5',
    '#00d97e',
    '#e63757',
    '#39afd1',
    '#fd7e14',
    '#02a8b5',
    '#727cf5',
    '#6b5eae',
    '#ff679b',
    '#f6c343'
];

export const themeColors = {
    primary: '#2c7be5',
    secondary: '#748194',
    success: '#00d27a',
    info: '#27bcfd',
    warning: '#f5803e',
    danger: '#e63757',
    light: '#f9fafd',
    dark: '#0b1727'
};

export const grays = {
    white: '#fff',
    100: '#f9fafd',
    200: '#edf2f9',
    300: '#d8e2ef',
    400: '#b6c1d2',
    500: '#9da9bb',
    600: '#748194',
    700: '#5e6e82',
    800: '#4d5969',
    900: '#344050',
    1000: '#232e3c',
    1100: '#0b1727',
    black: '#000'
};

export const darkGrays = {
    white: '#fff',
    1100: '#f9fafd',
    1000: '#edf2f9',
    900: '#d8e2ef',
    800: '#b6c1d2',
    700: '#9da9bb',
    600: '#748194',
    500: '#5e6e82',
    400: '#4d5969',
    300: '#344050',
    200: '#232e3c',
    100: '#0b1727',
    black: '#000'
};

export const getGrays = isDark => (isDark ? darkGrays : grays);

export const rgbColors = colors.map(color => rgbColor(color));
export const rgbaColors = colors.map(color => rgbaColor(color));

//===============================
// Echarts
//===============================
export const getPosition = (pos, params, dom, rect, size) => ({
    top: pos[1] - size.contentSize[1] - 10,
    left: pos[0] - size.contentSize[0] / 2
});

//===============================
// E-Commerce
//===============================
export const calculateSale = (base, less = 0, fix = 2) => (base - base * (less / 100)).toFixed(fix);
export const getTotalPrice = (cart, baseItems) =>
    cart.reduce((accumulator, currentValue) => {
        const { id, quantity } = currentValue;
        const { price, sale } = baseItems.find(item => item.id === id);
        return accumulator + calculateSale(price, sale) * quantity;
    }, 0);

//===============================
// Helpers
//===============================
export const getPaginationArray = (totalSize, sizePerPage) => {
    const noOfpages = Math.ceil(totalSize / sizePerPage);
    const array = [];
    let pageNo = 1;
    while (pageNo <= noOfpages) {
        array.push(pageNo);
        pageNo = pageNo + 1;
    }
    return array;
};

//===============================
// Date formatter
//=======================
export const getDate = (date) => {
    return moment(date).format('DD-MM-YYYY');
};

export const getTime = (date) => {
    return moment(date).format('HH:mm');
};

export const getFullDate = (date) => {
    return moment(date).format('LL, HH:mm');
}

export const getDateWithTime = (date) => {
    return moment(date).format('LL, HH:mm');
};

export const getValidFormatter = (promotion) => {

    return promotion.validBoolean ? 'Yes' : 'No';
}

export const getBooleanFormatter = (value) => {
    return value ? 'Yes' : 'No';
}

export const capitalLetter = someString => {
    return someString.charAt(0).toUpperCase() + someString.slice(1);
}

export const unifiedTableClass = () => {
    return 'py-2 align-middle';
}

export const formatMoney = (money) => {
    let sum = 0;
    if (money) {
        sum = money;
    }

    return sum.toFixed(2) + " EUR"
}

export const languages = () => {
    return {
        'lv': 'LV',
        'lt': 'LT',
        'ee': 'EE',
    }
}

export const badgeFormatter = status => {
    let color = '';
    let icon = '';
    let text = '';
    switch (status) {
        case true:
            color = 'completed';
            icon = 'check';
            text = 'Yes';
            break;
        case false:
            color = 'canceled';
            icon = 'ban';
            text = 'No';
            break;
    }

    return (
        <Badge color={`${color}`} className="rounded-capsule fs--1">
        {text}
        <FontAwesomeIcon icon={icon} transform="shrink-2" className="ml-1" />
      </Badge>
    );
};

export const userBadgeFormatter = status => {
    let color = '';
    let icon = '';
    let text = '';
    switch (status) {
        case true:
            color = 'canceled';
            icon = 'ban';
            text = 'Blocked';
            break;
        default:
            color = 'completed';
            icon = 'check';
            text = 'Valid';
            break;


    }

    return (
        <Badge color={`${color}`} className="rounded-capsule fs--1">
        {text}
        <FontAwesomeIcon icon={icon} transform="shrink-2" className="ml-1" />
      </Badge>
    );
};

export const getJobStatuses = () => {

    return [{
            name: '',
            label: 'Visi uzdevumi',
        },
        {
            name: 'created',
            label: 'Izveidoti',
        },
        { name: 'inprogress', label: 'Redzēti' },
        { name: 'completed', label: 'Uzkrāvušies' }
    ]
}

export const jobBadgeFormatter = status => {
    let color = '';
    let text = '';
    switch (status) {
        case 'active':
            color = 'success';
            text = 'Aktīvs';
            break;
        case 'unactive':
            color = 'warning';
            text = 'Neaktīvs';
            break;
        default:
            color = 'warning';
            text = status;
    }

    return (
        <Badge color={`${color}`} className="rounded-capsule fs--1">
           {text}
      </Badge>
    );
};

export const jobStatusFormatter = status => {
    let color = '';
    let text = '';
    switch (status) {
        case 'created':
            color = 'secondary';
            text = 'Izveidots';
            break;
        case 'inprogress':
            color = 'info';
            text = 'Redzēts';
            break;
        case 'completed':
            color = 'success';
            text = 'Uzkrāvies';
            break;
        default:
            color = 'warning';
            text = status;
    }

    return (
        <Badge color={`${color}`} className="rounded-capsule fs--1">
           {text}
      </Badge>
    );
};

export const CSVjobStatusFormatter = status => {
    let text = '';
    switch (status) {
        case 'created':
            text = 'Izveidots';
            break;
        case 'inprogress':
            text = 'Redzēts';
            break;
        case 'completed':
            text = 'Uzkrāvies';
            break;
        default:
            text = status;
    }

    return text
};

export const getDurationTypes = () => {
    return [{
            'name': 'forever',
            'label': 'Forever'
        },
        {
            'name': 'once',
            'label': 'Once'
        },
        {
            'name': 'multiple_months',
            'label': 'Multiple months'
        }
    ];
}

export const getRedemptionFormatter = (promotion) => {
    const max = getMaxRedemptions(promotion);

    return <p className="m-0 p-0">{promotion.redemption} / {max}</p>
}

export const getMaxRedemptions = (promotion) => {
    const infinity = <FontAwesomeIcon icon={faInfinity} className="ml-1 fs--2" />;
    const max = promotion.maxRedemptions !== null ? promotion.maxRedemptions : infinity;

    return max;
}


export const getPaymentValue = (promotionValue) => {
    let val = '-';
    if (promotionValue) {
        val = promotionValue
    }

    return val;
}

export const getExpiresFormatter = (date) => {
    const infinity = <FontAwesomeIcon icon={faInfinity} className="ml-1 fs--2" />;

    if (!date) {
        return infinity
    }
    return getFullDate(date);
}

export const getTermsFormatter = (promotion) => {
    const duration = getDurationTypes().find(type => {
        return type.name === promotion.duration
    });
    let durationLabel = '';
    if (duration) {
        durationLabel = duration.label;
    }

    if (promotion.duration === 'multiple_months') {
        durationLabel = " for " + promotion.durationValue + ' months';
    }

    let termsValue = ''
    if (promotion.termsValue) {
        termsValue = promotion.termsValue;
    }

    return `${termsValue} ${promotion.terms === 'percentage' ? '%' : 'Eur'} off ${durationLabel}`
}

export const getTermsLabel = (promotion) => {
    const duration = getDurationTypes().find(type => {
        return type.name === promotion.duration
    });

    if (promotion.duration === 'multiple_months') {
        return promotion.durationValue + ' months';
    }

    return duration && duration.label ? duration.label : promotion.duration;
}


export const defaultPerPage = 41;

function randStr(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export const CountryOptions = [{
        value: 'latvia',
        label: 'Latvia',
    },
    {
        value: 'estonia',
        label: 'Estonia',
    },
    {
        value: 'lithuania',
        label: 'Lithuania',
    }
];

export const LanguageOptions = [{
        value: 'latvian',
        label: 'Latvian',
    },
    {
        value: 'estonian',
        label: 'Estonian',
    },
    {
        value: 'lithuanian',
        label: 'Lithuanian',
    },
    {
        value: 'english',
        label: 'English',
    },
];

export const getTaskNames = [{
        _id: 1,
        name: "Terminālis"
    },
    {
        _id: 2,
        name: "Igaunija"
    }
]


export const capitalize = str => (str.charAt(0).toUpperCase() + str.slice(1)).replace(/-/g, ' ');