import React from 'react';
import Avatar from '../common/Avatar';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faWrench } from "@fortawesome/free-solid-svg-icons";
import image from '../../assets/img/user-avatar.png';

const ProfileDropdown = () => {


    // const image = localStorage.getItem('user_image');
    return (
        <UncontrolledDropdown nav inNavbar>
        <DropdownToggle nav className="pr-0">
          <Avatar src={image} />
        </DropdownToggle>
        <DropdownMenu right className="dropdown-menu-card">
          <div className="bg-white rounded-soft py-2">
            {/* <DropdownItem className="font-weight-bold" href="/pages/settings">
              <FontAwesomeIcon icon={faWrench} className="mr-1" />
              <span>Uzstādījumi</span>
            </DropdownItem> 
            <DropdownItem divider /> */}
            <DropdownItem className="font-weight-bold text-warning" to="/authentication/basic/logout" href="/logout">
                <FontAwesomeIcon icon={faSignOutAlt} className="mr-1" />
                Iziet
            </DropdownItem>
          </div>
        </DropdownMenu>
      </UncontrolledDropdown>

    );
};

export default ProfileDropdown;