export const setLoading = (flag) => {
    return {
        type: 'SET_LOADING',
        flag
    }
}

export const setError = (error) => {
    return {
        type: 'SET_ERROR',
        error
    }
}

export const setTasks = (tasks) => {
    return {
        type: 'SET_TASKS',
        tasks
    }
}

export const setCreatedJobs = (tasks) => {
    return {
        type: 'SET_CREATED_TASKS',
        tasks
    }
}

export const setInprogressJobs = (tasks) => {
    return {
        type: 'SET_INPROGRESS_TASKS',
        tasks
    }
}

export const setCompletedJobs = (tasks) => {
    return {
        type: 'SET_COMPLETED_TASKS',
        tasks
    }
}