import { combineReducers } from "redux";
import userReducer from "./userReducer";
import tasksReducer from "./tasksReducer";
import trailersReducer from "./trailersReducer";
import clientsReducer from "./clientsReducer";
import driversReducer from "./driversReducer";
import carsReducer from "./carsReducer";
import silosReducer from "./silosReducer";

export default combineReducers({
    userReducer,
    tasksReducer,
    trailersReducer,
    clientsReducer,
    driversReducer,
    carsReducer,
    silosReducer
})