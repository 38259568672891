import React, { Fragment, useEffect, useState, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import 'moment/locale/lv';
import { setTasks } from "../../actions/tasks";
import { Card, CardBody, CustomInput, Form, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Col, Row } from 'reactstrap';
import Select from 'react-select';
import Datetime from 'react-datetime';
import { getDurationTypes, getDate, getTaskNames, getTime } from "../../helpers/utils";
import { errorContainer } from "../utilities/ErrorContainer";
import TasksService from '../../services/tasks';
import { toast } from "react-toastify";
import useDriversFetch from "../../hooks/useDriversFetch";
moment.locale('lv');

function TaskView(data) {
    const dispatch = useDispatch();
    const tasks = useSelector(state => state.tasksReducer.tasks);
    const drivers = useSelector(state => state.driversReducer.drivers);
    const clients = useSelector(state => state.clientsReducer.clients);
    const silos = useSelector(state => state.silosReducer.silos);

    const [collapseOne, collapseOneOpen] = useState(false);
    const [name, setName] = useState(null);
    const [silo, setSilo] = useState(null);
    const [driver, setDriver] = useState(null);
    const [client, setClient] = useState(null);
    const [deliveryTime, setDeliveryTime] = useState(moment(data.data.deliveryTime));
    const [deliveryDate, setDeliveryDate] = useState(moment(data.data.deliveryDate));
    const [note, setNote] = useState(data.data.note);

    useEffect(() => {
        if (data.data.name) {
            let getName = getTaskNames.filter(item => { return item.name == data.data.name })
            if (getName.length !== 0) {
                setName({ value: getName[0].id, label: getName[0].name })
            }
        }
        if (data.data.silo) {
            setSilo({ value: data.data.silo._id, label: data.data.silo.name })
        }

        if (data.data.driver) {
            setDriver({ value: data.data.driver._id, label: data.data.driver.name })
        }

        if (data.data.client) {
            setClient({ value: data.data.client._id, label: data.data.client.name })
        }
    }, []);

    const saveTask = () => {
        TasksService.update(data.data._id, name && name.label, data.data.status, silo && silo.value, driver && driver.value, client && client.value, deliveryDate, deliveryTime, note)
            .then(res => {
                if (res.errors) {
                    var errors = res.errors.msg;
                    errors.map(error => {
                        errorContainer(error)
                    })
                } else {
                    toast.success('Uzdevums atjaunots');
                    TasksService.getAll()
                        .then(res => {
                            setTimeout(() => {
                                collapseOneOpen(!collapseOne);
                            }, 800)
                            dispatch(setTasks(res))
                        })
                        .catch((err) => {
                            console.log(err)
                        });
                }

            })
            .catch((err) => {
                errorContainer(err)
            });
    }

    let nameSelect = [];

    getTaskNames && getTaskNames.map(name => (
        nameSelect.push({ value: name._id, label: name.name })
    ))

    let silosSelect = [];

    silos && silos.map(silo => (
        silosSelect.push({ value: silo._id, label: silo.name })
    ))

    let driversSelect = [];

    drivers && drivers.map(driver => (
        driversSelect.push({ value: driver._id, label: driver.name })
    ))

    let clientSelect = [];

    clients && clients.map(client => (
        clientSelect.push({ value: client._id, label: client.name })
    ))

    return (
        <Fragment>
      <a className="font-weight-semi-bold" href="#" onClick={() => collapseOneOpen(!collapseOne)}>
        {data.data.name}
      </a>
      <Modal isOpen={collapseOne} toggle={() => collapseOneOpen(!collapseOne)} size={'lg'} centered>
        <ModalHeader>{data.data.name}</ModalHeader>
        <ModalBody>
         <Form>
           <Row>
           <Col>
            <FormGroup>
              <Label for="taskName">Darba nosaukums</Label>
              <Select
                id="taskName"
                placeholder="Nosaukums"
                value={name}
                options={nameSelect}
                isClearable
                noOptionsMessage={() => "Nav tāda ieraksta"}
                placeholder="Izvēlies nosaukumu"
                onChange={event => setName(event)}/>
            </FormGroup>
            </Col>
            <Col>
            <FormGroup>
              <Label for="taskSilo">Silos</Label>
              <Select
                id="siloSelect"
                value={silo}
                options={silosSelect}
                isClearable
                placeholder="Izvēlies silo"
                onChange={event => setSilo(event)}/>
            </FormGroup>
            </Col>
            <Col>
            <FormGroup>
              <Label for="driverSelect">Šoferis</Label>
              <Select
                id="driverSelect"
                value={driver}
                options={driversSelect}
                isClearable
                placeholder="Izvēlies šoferi"
                onChange={event => setDriver(event)}/>
            </FormGroup>
            </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label for="clientSelect">Klients</Label>
                  <Select 
                    type="select"
                    name="select"
                    id="clientSelect"
                    value={client}
                    options={clientSelect}
                    isClearable
                    placeholder="Izvēlies klientu"
                    onChange={event => setClient(event)}/>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label for="deliveryTime">Piegādes datums</Label>
                  <Datetime 
                    timeFormat={false}
                    bsSize="sm"
                    id="deliveryDate"
                    value={deliveryDate}
                    onChange={event => setDeliveryDate(event)}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label for="deliveryTime">Piegādes laiks</Label>  
                  <Datetime 
                    dateFormat={false}
                    timeFormat={"HH:mm"}
                    locale="lv"
                    closeOnSelect={true}
                    bsSize="sm"
                    id="deliveryTime"
                    value={deliveryTime}
                    onChange={event => setDeliveryTime(event)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
              <FormGroup>
              <Label for="taskNote">Piezīmes</Label>
              <Input
                type="textarea"
                name="note"
                id="taskNote"
                placeholder="Darba piezīmes šoferim"
                value={note}
                onChange={event => setNote(event.target.value)}
              />
            </FormGroup>
            </Col>
            </Row>
        </Form>
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => collapseOneOpen(!collapseOne)}>
            Atcelt
          </Button>
          <Button color="primary" onClick={saveTask}>Saglabāt</Button>
        </ModalFooter>
      </Modal>
    </Fragment>
    );
}

export default TaskView;