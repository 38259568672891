import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Redirect } from 'react-router-dom';
import Layout from './layouts/Layout';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-datetime/css/react-datetime.css';
import 'react-image-lightbox/style.css';
import { LayoutContext, LoginContext } from "./context/Context";

const App = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    return (
        <Router basename={process.env.PUBLIC_URL}>
            <LoginContext.Provider value={{
                email,
                setEmail,
                password,
                setPassword
            }} >
                {/*{redirect2 ? <Redirect to={`/login`} /> : null}*/}
                {/*<LayoutContext.Provider value={countriesArr} >*/}
                <Layout />
                {/*</LayoutContext.Provider>*/}
            </LoginContext.Provider>
        </Router>
    );
};

export default App;